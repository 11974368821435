import React from 'react'

import { UserHeader } from '@/src/components-new/header/user-header/UserHeader';

// import { UserMobileNavFooter } from '../../footers/UserMobileNavFooter';
import { UserMobileNavFooter } from '@/src/components-new/footer/user-mobile-nav-footer/UserMobileNavFooter';
import { UserFooterNew } from '@/src/components-new/footer/userFooter';

import { UserFeedbackWidget } from '@/src/components-etc/user-feedback-widget/UserFeedbackWidget';

interface UserLayoutProps {
    className?: string;
    children?: JSX.Element | JSX.Element[];
}

export const UserLayout = ({
    className,
    children,
}: UserLayoutProps) => {


    return (
        <div className='flex flex-col w-screen '>
            <div className='flex flex-col min-h-screen'>
                <div 
                    className='w-full top-0 sticky'
                    style={{zIndex:40}}
                > 
                    <UserHeader />
                </div>

                <div className={`w-full h-full overflow-y-scroll ${className}`}>
                    {children}
                </div>
            </div>

            <UserFooterNew />
            <div 
                className='md:hidden w-full bottom-0 sticky'
                style={{zIndex:40}}
            >
                <UserMobileNavFooter/>
            </div>
            <UserFeedbackWidget/>
        </div>
    )
}